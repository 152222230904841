window.storeAccount = {
  handler: {}
};

storeAccount.ready = function(currentLocale) {
  var meta_locale               = "meta[name='locale']",
      user_form                 = document.querySelector('#signup-form'),
      $create_account_button    = $("#submit-account"),
      $address_country_dropdown = $("#address_country_id"),
      $address_state_dropdown   = $("#address_state_id"),
      $plan_switcher            = $("#plan-switcher"),
      $plan_switcher_label      = $("#plan-switcher-label"),
      $subscribe_element        = $(".subscribe-element"),
      $subscribe_element_button = $(".subscribe-element-button"),
      $account_plan_id          = $("#account-plan-id"),
      $trial_email_field        = $("input[name='free_trial_email']"),
      $cta_button               = $("#cta_button"),
      $spinner                  = $(".spinner-border"),
      $account_name_field       = $("#account-name-field"),
      $account_name_valid       = $("#account-name-valid-feedback"),
      $account_name_invalid     = $("#account-name-invalid-feedback"),
      locale                    = currentLocale,
      handler
  ;
  
  handler = {
    check_name_availability: function() {
      var account_value = $account_name_field.val();
      $.ajax({
        url: '/api/v1/accounts/check_account_name',
        type: "get",
        data: { n: account_value },
        beforeSend: function(xhr) {
          $spinner.show();
        },
        success: function(data) {
          $spinner.hide();
          $account_name_field.addClass(data.results.message_class);
          if (data.results.availability) {
            $account_name_valid.html(data.results.message);
          } else {
            $account_name_invalid.html(data.results.message);
          }
        }
      });
    },
    switch_billed_frequency: function(element, checkStatus) {
      var frequency = "month";
      if (checkStatus) {
        $plan_switcher_label.html("<h6>" + annual_plan_label + "</h6>");
        frequency = "year";
      } else {
        $plan_switcher_label.html("<h6>" + monthly_plan_label + "</h6>");
        frequency = "month";
      }
      var accountId = $("meta[name='account-id']").attr("content");
      $.ajax({
        url: '/'+ locale + '/accounts/' + accountId + '/switch',
        type: "post",
        data: { frequency: frequency },
        success: function(data) {
          console.log("Switch frequency.....");
        }
      });        
    },
    switch_subscription_plan: function(element, planId) {
      var borderClass = "plan-element--box--border--round--colorone--center";
      var buttonBorderClass = "btn--big--round--colorone";
      $subscribe_element.removeClass(borderClass);
      $subscribe_element_button.removeClass(buttonBorderClass);
      $(element).addClass(borderClass);
      $(element).find(".subscribe-element-button").addClass(buttonBorderClass);
      $account_plan_id.val(planId);
    },
    init_state_dropdown: function() {
      if ($address_country_dropdown.length) {
        handler.set_state_dropdown();
      }      
    },
    set_state_dropdown: function() {
      var selectedCountry = $address_country_dropdown.children("option:selected").val();
      $.ajax({
        url: '/api/v1/states?country_id=' + selectedCountry,
        type: 'get',
        success: function(data) {
          console.log(data)
          var results = data.results
          $address_state_dropdown.find('option').remove();
          if (results && results.length > 0) {
            $address_state_dropdown.append($('<option>', {
              value: '',
              text: "--- Select a State ---"
            }))
            $.each(results, function(i, result) {
              console.log(result)
              $address_state_dropdown.append($('<option>', {
                value: result.id,
                text: result.name
              }))
            });
          } else {
            $address_state_dropdown.append($('<option>', {
              value: '',
              text: "No States Available"
            }))
          }
        }
      });                  
    },
    init: function() {
      locale = document.querySelector(meta_locale).content;
      $spinner.hide();
      
      handler.init_state_dropdown();
      
      $address_country_dropdown.on('change', function() {
        handler.set_state_dropdown();
      });
  
      $subscribe_element.on("click", function() {
        var planId = $(this).data("plan-id");
        handler.switch_subscription_plan(this, planId);
      });
  
      $plan_switcher.on('change', function() {
        var isChecked = $(this).is(':checked');
        handler.switch_billed_frequency(this, isChecked);
      });
            
      $cta_button.on("click", function() {
        var locale = document.querySelector("meta[name='locale']").content
        var email = $trial_email_field.val();
        window.location.href = "/auth/registration/signup?locale=" + locale + "&email=" + email;
      });
     
      $account_name_field.on('focusout', function() {
        handler.check_name_availability();
      });
             
    }
  };

  storeAccount.handler = handler;
  handler.init();

}